import {
  Avatar,
  Box,
  Flex,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { studyGroupViewPath } from "../../../../routes";
import { Tag } from "../../shared/components/atoms";
import { CalendarMonthIcon, ScheduleIcon } from "../../shared/components/icons";
import { viewEndAt, viewingTime } from "../../shared/lib/studyGroupCardUtil";
import { StudyGroup } from "../../shared/lib/types";

const StudyGroupCard = ({ studyGroup }: { studyGroup: StudyGroup }) => {
  return (
    <Box
      border="1px solid #E2E8F0"
      w="full"
      h="full"
      borderRadius={2}
      overflow="hidden"
      _hover={{
        outline: "4px solid",
        outlineColor: "primary",
      }}
    >
      <Box
        as="a"
        cursor="pointer"
        h="full"
        display="flex"
        flexDirection="column"
        href={studyGroupViewPath(studyGroup.code)}
      >
        <Image src={studyGroup.thumbnail_url} aspectRatio={2 / 1} w="full" />
        <Stack px={4} pt={3} pb={4} bg="white" gap={4} flex={1}>
          <Flex
            pb={3}
            fontSize="xs"
            justify="space-between"
            borderBottom="1px solid rgba(0, 0, 0, 10%)"
          >
            <HStack gap={0.5}>
              <CalendarMonthIcon />
              <Box as="span" pt={0.5}>
                {viewEndAt(studyGroup)}
              </Box>
            </HStack>
            <HStack gap={0.5}>
              <ScheduleIcon />
              <Box as="span" pt={0.5}>
                {viewingTime(studyGroup)}
              </Box>
            </HStack>
          </Flex>
          <Stack gap={2} h="full">
            <HStack wrap="wrap" gap={1}>
              {studyGroup.tags.map((tag) => (
                <Tag key={tag.id}>{tag.name}</Tag>
              ))}
            </HStack>
            <Text flex={1}>{studyGroup.title}</Text>
            <HStack mt={2} gap={1.5}>
              <Avatar
                boxSize={6}
                src={studyGroup.instructor_info.instructor_image_url}
                ignoreFallback
                loading="lazy"
              />
              <Box as="span" fontSize="sm">
                {studyGroup.instructor_info.name}
              </Box>
            </HStack>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default StudyGroupCard;
