import { Box, Container, Divider, Heading, Stack } from "@chakra-ui/react";
import dayjs from "dayjs";
import { default as React } from "react";
import { Helmet } from "react-helmet";
import { announcementsPath } from "../../../routes";
import { Flash } from "../../shared/lib/types";
import AnnouncementCategory from "../shared/components/atoms/AnnouncementCategory";
import Background from "../shared/components/atoms/Background";
import GoBackLink from "../shared/components/atoms/GoBackLink";
import Header from "../shared/components/atoms/Header";
import ShadowCard from "../shared/components/atoms/ShadowCard";
import Application from "../shared/components/layouts/Application";
import { Announcement, UserSharedCurrentUser } from "../shared/lib/types";

const AnnouncementsShow = ({
  announcement,
  flash,
  currentUser,
}: {
  announcement: Announcement;
  flash: Flash;
  currentUser: UserSharedCurrentUser;
}) => {
  return (
    <Application flash={flash} currentUser={currentUser}>
      <Helmet
        style={[
          {
            cssText: `
        .all-revert {
          * {
            all: revert;
          }

          img {
            max-width: 100%;
          }

          table tr td {
            border: 1px solid #dadada;
            min-width: 2em;
            padding: .4em;
            user-select: text;
            vertical-align: middle;
          }
        }
      `,
          },
        ]}
      />
      <Header currentUser={currentUser} />
      <Background>
        <Container mt={{ base: 6, md: 12 }} maxW="container.lg">
          <Stack spacing={4}>
            <GoBackLink href={announcementsPath()}>
              お知らせ一覧へ戻る
            </GoBackLink>
            <ShadowCard>
              <Box>
                <AnnouncementCategory
                  category={announcement.category}
                  w="fit-content"
                />
                <Heading mt={5} fontSize={"xl"} as="h1">
                  {announcement.title}
                </Heading>
                <Box mt={2} color="gray" fontSize="sm">
                  {dayjs(
                    announcement.publish_started_at ?? announcement.created_at,
                  ).format("L")}
                </Box>
              </Box>
              <Divider borderColor="#8DCCBE" mt={{ base: 5, md: 6 }} />
              <Box
                mt={{ base: 5, md: 6 }}
                className="all-revert"
                dangerouslySetInnerHTML={{ __html: announcement.content }}
              />
            </ShadowCard>
          </Stack>
        </Container>
      </Background>
    </Application>
  );
};

export default AnnouncementsShow;
