import dayjs from "dayjs";
import { StudyGroup } from "./types";
import { secondsToHoursMinutes } from "./dateUtils";

export const viewEndAt = (studyGroup: StudyGroup) => {
  return studyGroup.ended_at
    ? `${dayjs(studyGroup.ended_at).format("L(dd) HH:mm")} まで`
    : "視聴期限無し";
};

export const viewingTime = (studyGroup: StudyGroup) => {
  return studyGroup.viewing_time
    ? `${secondsToHoursMinutes(studyGroup.viewing_time)}`
    : "計算中...";
};
