import {
  Box,
  Container,
  Divider,
  Flex,
  HStack,
  Heading,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  postCommentsPath,
  postPath,
  postResolvedPath,
  postsPath,
} from "../../../routes";
import { Flash } from "../../shared/lib/types";
import useFlash from "../shared/lib/useFlash";
import useRequest from "../shared/lib/useRequest";
import {
  PostsShow as PostsShowProp,
  RelatedPosts,
  UserSharedCurrentUser,
} from "../shared/lib/types";
import { Button } from "../shared/components/atoms";
import Background from "../shared/components/atoms/Background";
import FetchedComments from "../shared/components/atoms/FetchedComments";
import Footer from "../shared/components/atoms/Footer";
import GoBackLink from "../shared/components/atoms/GoBackLink";
import Header from "../shared/components/atoms/Header";
import HelpMessage from "../shared/components/atoms/HelpMessage";
import NotApprovedUserOverlay from "../shared/components/atoms/NotApprovedUserOverlay";
import PostDetail from "../shared/components/atoms/PostDetail";
import ServiceCaution from "../shared/components/atoms/ServiceCaution";
import Tag from "../shared/components/atoms/Tag";
import TemporaryComments from "../shared/components/atoms/TemporaryComments";
import ChatBubbleIcon from "../shared/components/icons/ChatBubbleIcon";
import DoneIcon from "../shared/components/icons/DoneIcon";
import Application from "../shared/components/layouts/Application";
import AnonymousField from "./components/AnonymousField";
import BackToTop from "./components/BackToTop";
import {
  CommentFormFields,
  useCommentForm,
} from "./components/CommentReplyForm";

const CommentForm = ({
  currentUser,
  fetchComments,
  post,
  onSubmit,
}: {
  currentUser: UserSharedCurrentUser;
  fetchComments: (post_code: string) => void;
  post: PostsShowProp;
  onSubmit?: () => void;
}) => {
  const request = useRequest();
  const showFlash = useFlash();

  const commentFormState = useCommentForm({
    onSubmit: async (data) => {
      const res = await request(postCommentsPath(post.code), "POST", data);
      if (res.ok) {
        if (onSubmit) onSubmit();
        fetchComments(post.code);
      } else if (res.status === 404) {
        showFlash({
          error: (
            <>
              投稿が見つかりませんでした。
              <br />
              ページを再読み込みしてください。
            </>
          ),
        });
      }
    },
    hasAnonymousField: currentUser?.is_anonymousable,
    defaultValues: {
      content: "",
      anonymous: post.previous_post_anonymous,
    },
  });

  const anonymous = commentFormState.methods.watch("anonymous");

  return (
    <Box
      mt={{ base: 6, sm: 10 }}
      as="form"
      onSubmit={commentFormState.onSubmit}
    >
      <Heading fontSize="lg">質問にコメントする</Heading>
      {post.is_resolved ? (
        <Text
          mt={{ base: 5, md: 6 }}
          mb={{ base: 6, md: 7 }}
          fontSize="sm"
          textAlign="center"
          color="#6D787D"
          wordBreak="keep-all"
          overflowWrap="anywhere"
        >
          解決済みの質問のため、
          <wbr />
          コメントできません
        </Text>
      ) : (
        <>
          <Box mt={4}>
            {currentUser?.is_anonymousable && (
              <AnonymousField
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                control={commentFormState.methods.control}
                anonymous={anonymous ?? ""}
                currentUser={currentUser}
                showPreview={false}
                label="コメント者の表示名"
              />
            )}
            <Box mt={4}>
              <CommentFormFields
                formState={commentFormState}
                currentUser={currentUser}
                anonymous={anonymous === "1"}
              />
            </Box>
          </Box>
          <Button
            w="full"
            mt={{ base: 6, sm: 8 }}
            isDisabled={!commentFormState.isSubmittable}
            isLoading={commentFormState.isSubmitting}
            type="submit"
          >
            コメントを送信
          </Button>
        </>
      )}
    </Box>
  );
};

const Page = ({
  post,
  relatedPosts,
  current_user,
}: {
  post: PostsShowProp;
  relatedPosts: RelatedPosts;
  current_user: UserSharedCurrentUser;
}) => {
  const [fetchCommentsResult, setFetchCommentsResult] = useState({
    // TODO: storybookに渡すためにしているのでmockして無くしたい
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    comments: post.comments ?? [],
    comments_count: post.comments_count,
  });

  const request = useRequest();
  const flash = useFlash();
  const ref = useRef<HTMLDivElement>(null);

  const fetchComments = useCallback(
    async (post_code: string) => {
      const res = await request(postCommentsPath(post_code), "GET");

      if (res.ok) {
        setFetchCommentsResult(await res.json());
      } else if (res.status === 401) {
        flash({
          error: "エラーが発生しました。ページを再読み込みしてください。",
        });
      }
    },
    [request, flash],
  );

  useEffect(() => {
    if (current_user?.is_all_public_feature_accessible) {
      void fetchComments(post.code);
    }
  }, [
    fetchComments,
    post.code,
    current_user?.is_all_public_feature_accessible,
  ]);

  const resolveModalDisclosure = useDisclosure();

  return (
    <Container maxW="container.md" mt={{ base: 10, sm: 14 }}>
      <Flex justify="space-between" alignItems="center">
        <GoBackLink href={postsPath()}>Q&A一覧に戻る</GoBackLink>
        <HelpMessage
          as="a"
          href="https://www.notion.so/usr-aloop/b6a14a7dd65b444bae3bd15283b9b302?pvs=4#bcbba16a34ff4444a26d1764e13fab12"
          target="_blank"
          rel="noreferrer"
        >
          Q&A投稿方法
        </HelpMessage>
      </Flex>
      {!post.is_resolved && post.is_own && (
        <Flex
          bgColor="#D9EEE9"
          py={{ base: 3, md: 4 }}
          px={{ base: 3, md: 6 }}
          align="center"
          justify="space-between"
          mt={3}
          mb={4}
        >
          <Popover>
            <PopoverTrigger>
              <HelpMessage cursor="pointer">
                コメント受付を終了したい場合
              </HelpMessage>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverBody>
                <Text fontSize="sm">
                  投稿を解決するとコメントができなくなります。また、解決済みにした投稿は元に戻すことができません。
                </Text>
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <Button
            leftIcon={<DoneIcon />}
            size="sm"
            onClick={resolveModalDisclosure.onOpen}
          >
            解決した
          </Button>
        </Flex>
      )}
      {post.is_resolved && (
        <Flex
          bgColor="#D9EEE9"
          py={{ base: 2, md: 4 }}
          gap={{ base: 3, md: 6 }}
          align="center"
          justify="center"
          color="primary"
          mt={3}
          mb={4}
        >
          <DoneIcon boxSize={{ base: 8, md: 10 }} />
          <Box>
            <Text fontWeight="bold">この質問は解決済みです</Text>
            <Text fontSize="xs">※コメントや返信はできません</Text>
          </Box>
        </Flex>
      )}
      <Box mt={4}>
        <PostDetail post={post} currentUser={current_user}>
          <Box
            position="relative"
            pb={{ base: 4, sm: 7 }}
            px={{ base: 5, sm: 9 }}
            userSelect={!current_user?.is_all_public_feature_accessible ? "none" : undefined}
          >
            <Divider mt={{ base: 6, sm: 7 }} borderColor="#C4CCCA" />
            <Box>
              {current_user?.is_all_public_feature_accessible ? (
                <>
                  <FetchedComments
                    comments={fetchCommentsResult.comments}
                    currentUser={current_user}
                    post={post}
                  />
                  <div ref={ref} />
                </>
              ) : (
                <TemporaryComments />
              )}
            </Box>
            <Divider mt={{ base: 6, sm: 10 }} border="1px dashed #888888" />
            <CommentForm
              currentUser={current_user}
              fetchComments={fetchComments}
              post={post}
              onSubmit={() => {
                ref.current?.scrollIntoView({ behavior: "smooth" });
              }}
            />
            {!current_user?.is_all_public_feature_accessible && (
              <NotApprovedUserOverlay currentUser={current_user} />
            )}
          </Box>
        </PostDetail>
      </Box>
      {relatedPosts.length !== 0 && (
        <Box as="section" mt={{ base: 12, md: "72px" }}>
          <Heading>関連するQ&A</Heading>
          <Stack gap={{ base: 2, md: 3 }} mt={{ base: 3, md: 6 }}>
            {relatedPosts.map((relatedPost) => (
              <Stack
                bgColor="white"
                px={{ base: 4, md: 6 }}
                py={{ base: 4, md: 5 }}
                borderRadius={8}
                border="solid 1px #DCE5E3"
                gap={2}
                as="a"
                cursor="pointer"
                key={relatedPost.code}
                href={postPath(relatedPost.code)}
                _hover={{
                  outline: "4px solid",
                  outlineColor: "primary",
                }}
              >
                <Text
                  fontWeight="bold"
                  fontSize={{ base: "sm", md: "md" }}
                  noOfLines={{ base: 2, md: 1 }}
                >
                  {relatedPost.title}
                </Text>
                <HStack wrap="wrap" gap={1.5}>
                  {relatedPost.tags.map((tag) => (
                    <Tag key={tag.id}>{tag.name}</Tag>
                  ))}
                </HStack>
                <Text
                  fontSize={{ base: "xs", md: "sm" }}
                  noOfLines={{ base: 2, md: 1 }}
                >
                  {relatedPost.content}
                </Text>
                <Flex justify="space-between" fontSize="xs">
                  <Box as="span" color="#848484">
                    {dayjs(relatedPost.created_at).format("L LT")}
                  </Box>
                  <HStack gap={0.5}>
                    <ChatBubbleIcon boxSize={4} />
                    <Box as="span" pt={0.5}>
                      コメント {relatedPost.comments_count}件
                    </Box>
                  </HStack>
                </Flex>
              </Stack>
            ))}
          </Stack>
        </Box>
      )}
      <Box mt={{ base: 8, md: 14 }}>
        <ServiceCaution />
      </Box>
      <ResolveModal
        isOpen={resolveModalDisclosure.isOpen}
        onClose={resolveModalDisclosure.onClose}
        post={post}
      />
    </Container>
  );
};

const PostsShow = ({
  post,
  relatedPosts,
  flash,
  currentUser: currentUser,
}: {
  post: PostsShowProp;
  relatedPosts: RelatedPosts;
  flash: Flash;
  currentUser: UserSharedCurrentUser;
}) => {
  return (
    <Application flash={flash} currentUser={currentUser}>
      <Background color="brand.50" pb={0}>
        <Header currentUser={currentUser} />
        <Box position="relative" pb={6}>
          <BackToTop>
            <Page
              post={post}
              relatedPosts={relatedPosts}
              current_user={currentUser}
            />
          </BackToTop>
        </Box>
      </Background>
      <Footer />
    </Application>
  );
};

const ResolveModal = ({
  isOpen,
  onClose,
  post,
}: {
  isOpen: boolean;
  onClose: () => void;
  post: PostsShowProp;
}) => {
  const request = useRequest();

  const resolvePost = async () => {
    const res = await request(postResolvedPath(post.code), "PUT");

    if (res.ok) {
      location.reload();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>投稿を解決済みにする</ModalHeader>
        <ModalCloseButton />
        <ModalBody wordBreak="keep-all">
          <Text>解決済みにすると以下の操作を行うことができなくなります。</Text>

          <List mt={2}>
            <ListItem>・投稿の編集</ListItem>
            <ListItem>・コメント・返信の追加・編集</ListItem>
          </List>

          <Text fontWeight="bold" mt={4}>
            一度解決済みにすると解決済みを解除することはできませんのでご注意ください。
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button color="gray" mr={3} onClick={onClose}>
            閉じる
          </Button>
          <Button onClick={resolvePost}>解決済みにする</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PostsShow;
