import "../stylesheets/public.css";
import "dayjs/locale/ja";
import "../bundles/shared/lib/init";

import ReactOnRails from "react-on-rails";
import CommunitiesIndex from "../bundles/public/communities/CommunitiesIndex";
import StudyGroupsShow from "../bundles/public/study_groups/StudyGroupsShow";
import StudyGroupsIndex from "../bundles/public/study_groups/StudyGroupsIndex";
import PostsIndex from "../bundles/public/posts/PostsIndex";
import PostsShow from "../bundles/public/posts/PostsShow";
import HomeIndex from "../bundles/public/home/HomeIndex";
import TermsOfServiceShow from "../bundles/public/terms_of_services/TermsOfServiceShow";
import PrivacyPolicyShow from "../bundles/public/privacy_policies/PrivacyPolicyShow";
import ContactsNew from "../bundles/public/contacts/ContactsNew";
import ConfirmationDonesShow from "../bundles/public/confirmation_dones/ConfirmationDonesShow";
import AnnouncementsIndex from "../bundles/public/announcements/AnnouncementsIndex";
import AnnouncementsShow from "../bundles/public/announcements/AnnouncementsShow";
import ConfirmationSentsShow from "../bundles/public/confirmation_sents/ConfirmationSentsShow";
import ResetPasswordSentsShow from "../bundles/public/reset_password_sents/ResetPasswordSentsShow";
import UsersShow from "../bundles/public/users/UsersShow";
import * as Sentry from "@sentry/react";
import Fallback from "../bundles/shared/components/Fallback";

import "../bundles/shared/lib/sentry";
import React from "react";
import theme from "../bundles/public/shared/lib/theme";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { FlipperContext } from "../bundles/shared/lib/FlipperContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const withProviders = (Component) => {
  return function InnerComponent(props, railsContext) {
    Sentry.setContext("Additional", {
      current_user_code: railsContext.current_user_code,
      request_id: railsContext.request_id,
    });
    const result = () => (
      <Sentry.ErrorBoundary fallback={<Fallback />}>
        <ChakraProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <FlipperContext.Provider value={railsContext.flipper}>
              <Component {...props} />
            </FlipperContext.Provider>
          </QueryClientProvider>
        </ChakraProvider>
      </Sentry.ErrorBoundary>
    );
    return result;
  };
};

const components = {
  CommunitiesIndex,
  StudyGroupsShow,
  StudyGroupsIndex,
  PostsIndex,
  PostsShow,
  HomeIndex,
  TermsOfServiceShow,
  PrivacyPolicyShow,
  ContactsNew,
  ConfirmationDonesShow,
  AnnouncementsIndex,
  AnnouncementsShow,
  ConfirmationSentsShow,
  ResetPasswordSentsShow,
  UsersShow,
};

const withProvidersComponents = Object.keys(components).reduce((acc, key) => {
  acc[key] = withProviders(components[key]);
  return acc;
}, {});

ReactOnRails.register(withProvidersComponents);
