import { HStack, Stack, Text } from "@chakra-ui/react";
import React from "react";
import RegistrationButton from "./RegistrationButton";
import Button from "./Button";
import { newUserDatabaseAuthenticationSessionPath } from "../../../../../routes";
import { UserSharedCurrentUser } from "../../lib/types";

const NotApprovedUserOverlay = ({
  currentUser,
}: {
  currentUser: UserSharedCurrentUser;
}) => {
  return (
    <Stack
      position="absolute"
      color="black"
      top={0}
      left={0}
      bgColor="black"
      w="full"
      h="full"
      background="rgba(60, 170, 145, 0.70)"
      backdropFilter="blur(2px)"
      alignItems="center"
      justify="center"
      p={4}
    >
      <Text
        color="white"
        textAlign="center"
        fontWeight="bold"
        fontSize={{ base: "sm", sm: "md" }}
      >
        {currentUser == null
          ? "会員登録が完了すると、コメントを閲覧したり発言をすることが可能になります"
          : currentUser.is_temporary && !currentUser.applicationing
            ? "本人確認が完了すると、コメントを閲覧したり発言をすることが可能になります"
            : "本人確認の審査が完了するまでお待ちください。"}
      </Text>
      {(currentUser == null || !currentUser.applicationing) && (
        <HStack fontSize="sm" mt={1}>
          <RegistrationButton currentUser={currentUser} />
          {currentUser == null && (
            <Button
              as="a"
              cursor="pointer"
              color="gray"
              href={newUserDatabaseAuthenticationSessionPath()}
            >
              ログインする
            </Button>
          )}
        </HStack>
      )}
    </Stack>
  );
};

export default NotApprovedUserOverlay;
