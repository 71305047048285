import {
  Box,
  CloseButton,
  Container,
  Flex,
  HStack,
  Heading,
  Link,
  List,
  Stack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Controller, UseFormReturn, useForm } from "react-hook-form";
import * as yup from "yup";
import { contactsPath, privacyPolicyPath, rootPath } from "../../../routes";
import { Flash } from "../../shared/lib/types";
import useFiles from "../shared/lib/useFiles";
import useFlash from "../shared/lib/useFlash";
import useRequest from "../shared/lib/useRequest";
import { UserSharedCurrentUser } from "../shared/lib/types";
import { Button } from "../shared/components/atoms";
import Background from "../shared/components/atoms/Background";
import Dropzone from "../shared/components/atoms/Dropzone";
import { FormLabel, Input, Textarea } from "../shared/components/atoms/form";
import Header from "../shared/components/atoms/Header";
import ShadowCard from "../shared/components/atoms/ShadowCard";
import DescriptionIcon from "../shared/components/icons/DescriptionIcon";
import Application from "../shared/components/layouts/Application";
import { uploadFile } from "../../shared/lib/uploadFile";

const schema = () => {
  return yup.object().shape({
    name: yup.string().trim().required().label("氏名"),
    email: yup.string().trim().email().required().label("メールアドレス"),
    phone_number: yup.string().trim().phoneNumber().ensure().label("電話番号"),
    body: yup.string().trim().required().label("お問い合わせ内容"),
  });
};

type SchemaType = yup.InferType<ReturnType<typeof schema>>;

const ContactsNew = ({
  flash,
  currentUser,
}: {
  flash: Flash;
  currentUser: UserSharedCurrentUser;
}) => {
  const methods = useForm({
    defaultValues: {
      name: currentUser?.is_approved ? currentUser.full_name : "",
      email: currentUser?.email ?? "",
      phone_number: "",
      body: "",
    },
    resolver: yupResolver(schema()),
  });
  const { control, handleSubmit, reset } = methods;
  const { files, append, remove } = useFiles([], {
    maxCount: 3,
    maxTotalBytesize: 5 * 1000 * 1000,
  });
  const request = useRequest();
  const showFlash = useFlash();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const onSubmit = async (data: SchemaType) => {
    if (showConfirmation) {
      const signed_ids = await Promise.all(
        files.map(async (file) => {
          const res = await uploadFile({ file });
          return res.blob.signed_id;
        }),
      );
      const res = await request(contactsPath(), "POST", {
        contact: { ...data, files: signed_ids },
      });
      if (res.ok) {
        reset();
        location.href = rootPath();
      }
    } else {
      setShowConfirmation(true);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showConfirmation]);

  return (
    <Application flash={flash} currentUser={currentUser}>
      <Header currentUser={currentUser} />
      <Background>
        <Container mt={20} maxW="container.md">
          <ShadowCard>
            <Box as="form" onSubmit={handleSubmit(onSubmit)}>
              <Heading
                variant="h1"
                fontSize={{ base: 24, md: 32 }}
                fontWeight="semibold"
                textAlign="center"
              >
                お問い合わせフォーム
              </Heading>
              {showConfirmation && (
                <Heading
                  variant="h2"
                  fontSize={{ base: 24, md: 32 }}
                  fontWeight="semibold"
                  textAlign="center"
                  mt={2}
                >
                  入力内容確認
                </Heading>
              )}
              <Box textAlign="center" mt={7} py={6} bgColor="#EDF5F3">
                <Box
                  color="primary"
                  fontSize="sm"
                  fontWeight="bold"
                  wordBreak="keep-all"
                  overflowWrap="anywhere"
                >
                  受付時間内に届いたお問い合わせは、原則即日対応いたします。
                </Box>
                <Box fontSize="sm" fontWeight="bold" mt={6}>
                  ＜受付時間＞
                </Box>
                <Box mt={2}>月曜日～金曜日</Box>
                <Box fontSize="xs">（祝日、弊社休業日を除く）</Box>

                <Box mt={2}>10：00～12：00 / 13：00～16：00</Box>
                <Box fontSize="xs" wordBreak="keep-all" overflowWrap="anywhere">
                  ご入力いただきましたお客様の個人情報は、お問い合わせの対応に必要な範囲で使用いたします。
                  <br />
                  詳細につきましては、「
                  <Link
                    href={privacyPolicyPath()}
                    target="_blank"
                    rel="noreferrer"
                  >
                    個人情報保護方針
                  </Link>
                  」をご覧ください。
                </Box>
              </Box>
              <Box mt={12}>
                {showConfirmation ? (
                  <Confirmation
                    methods={methods}
                    files={files}
                    onBack={() => setShowConfirmation(false)}
                  />
                ) : (
                  <Stack gap={6}>
                    <Controller
                      control={control}
                      name="name"
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          label="氏名"
                          {...field}
                          error={error?.message}
                          maxW={{ base: undefined, md: "50%" }}
                          required
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="email"
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          label="メールアドレス"
                          {...field}
                          error={error?.message}
                          maxW={{ base: undefined, md: "50%" }}
                          required
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="phone_number"
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          label="電話番号"
                          {...field}
                          maxW={{ base: undefined, md: "50%" }}
                          error={error?.message}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="body"
                      render={({ field, fieldState: { error } }) => (
                        <Textarea
                          label="お問い合わせ内容"
                          {...field}
                          error={error?.message}
                          required
                          message={
                            <Box as="span" textColor="caution">
                              勉強会の内容に関するご質問はご対応いたしかねます。ご了承ください。
                            </Box>
                          }
                        />
                      )}
                    />
                    <Stack gap={1.5}>
                      <FormLabel>添付ファイル</FormLabel>
                      <Dropzone
                        multiple
                        accept={{
                          "image/png": [],
                          "image/jpeg": [],
                        }}
                        onDrop={(acceptedFiles) => {
                          append(acceptedFiles, (reason) => {
                            switch (reason) {
                              case "maxCount":
                                showFlash({
                                  error: "添付ファイルは3つまでです",
                                });
                                break;
                              case "maxTotalBytesize":
                                showFlash({
                                  error: "ファイルサイズの上限は5MBです",
                                });
                                break;
                            }
                          });
                        }}
                      />
                      <Box fontSize="xs" color="#6D787D">
                        PNGもしくはJPGファイルで、5MB未満のサイズでアップロードしてください。
                        <br />
                        添付可能なファイルは3つまでです。
                      </Box>
                      <FileList files={files} onRemove={remove} />
                    </Stack>
                    <Button type="submit" w="fit-content" mx="auto">
                      入力内容確認へ進む
                    </Button>
                  </Stack>
                )}
              </Box>
            </Box>
          </ShadowCard>
        </Container>
      </Background>
    </Application>
  );
};

const Confirmation = ({
  methods,
  files,
  onBack,
}: {
  methods: UseFormReturn<SchemaType>;
  files: File[];
  onBack: () => void;
}) => {
  const data = methods.getValues();
  return (
    <>
      <Stack gap={8}>
        {[
          { label: "氏名", required: true, value: data.name },
          { label: "メールアドレス", required: true, value: data.email },
          { label: "電話番号", required: false, value: data.phone_number },
          { label: "お問い合わせ内容", required: true, value: data.body },
          {
            label: "添付ファイル",
            required: false,
            value: <FileList files={files} />,
          },
        ].map(({ label, required, value }) => (
          <Box key={label}>
            <FormLabel required={required}>{label}</FormLabel>
            <Box mt={3} whiteSpace="pre-wrap">
              {value}
            </Box>
          </Box>
        ))}
      </Stack>
      <Flex mt={16} gap={3} justify="center">
        <Button onClick={onBack} variant="outline">
          <Box>
            <Box wordBreak="keep-all" overflowWrap="anywhere">
              入力内容を
              <wbr />
              修正
            </Box>
          </Box>
        </Button>
        <Button type="submit" isLoading={methods.formState.isSubmitting}>
          <Box wordBreak="keep-all" overflowWrap="anywhere">
            お問い合わせを
            <wbr />
            送信
          </Box>
        </Button>
      </Flex>
    </>
  );
};

const FileList = ({
  files,
  onRemove,
}: {
  files: File[];
  onRemove?: (idx: number) => void;
}) => {
  return (
    // CloseButtonの高さの影響を受けるので調整する
    <List spacing={onRemove ? 1 : 3}>
      {files.map((file, idx) => (
        <HStack key={idx} as="li">
          <DescriptionIcon />
          <Box fontWeight="bold">{file.name}</Box>
          {onRemove && <CloseButton onClick={() => onRemove(idx)} />}
        </HStack>
      ))}
    </List>
  );
};

export default ContactsNew;
