import { ButtonGroup } from "@chakra-ui/react";
import React from "react";
import { Button } from "../../shared/components/atoms";
import { UserSharedCurrentUser } from "../../shared/lib/types";
import useRequest from "../../shared/lib/useRequest";
import { currentPostThemePath } from "../../../../routes";
import useFlash from "../../shared/lib/useFlash";

const ChangeThemeButtons = ({
  currentUser,
}: {
  currentUser: UserSharedCurrentUser;
}) => {
  const theme =
    currentUser?.current_post_theme_name ??
    localStorage.getItem("current_post_theme_name") ??
    "detail";

  const compactButtonVariant = theme === "compact" ? "solid" : "outline";
  const detailButtonVariant = theme === "detail" ? "solid" : "outline";
  const request = useRequest();
  const showFlash = useFlash();

  const createButtonOnClick = (themeName: string) => {
    if (theme === themeName) return undefined;

    if (currentUser != null) {
      return async () => {
        const res = await request(currentPostThemePath(), "PUT", {
          name: themeName,
        });
        if (res.ok) {
          location.reload();
        } else {
          showFlash({ error: "もう一度お試しください" });
        }
      };
    } else {
      return () => {
        localStorage.setItem("current_post_theme_name", themeName);
        location.reload();
      };
    }
  };

  return (
    <ButtonGroup isAttached>
      <Button
        fontSize="xs"
        px={2}
        py={1}
        variant={compactButtonVariant}
        onClick={createButtonOnClick("compact")}
      >
        簡易表示
      </Button>
      <Button
        fontSize="xs"
        variant={detailButtonVariant}
        px={2}
        py={1}
        onClick={createButtonOnClick("detail")}
      >
        詳細表示
      </Button>
    </ButtonGroup>
  );
};

export default ChangeThemeButtons;
