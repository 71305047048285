import {
  Avatar,
  Box,
  Container,
  Flex,
  Grid,
  HStack,
  Heading,
  Image,
  Link,
  ListItem,
  Stack,
  StackProps,
  Text,
  UnorderedList,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { default as React, ReactNode, useEffect } from "react";
import {
  postsPath,
  studyGroupViewPath,
  studyGroupsPath,
} from "../../../routes";
import { Flash } from "../../shared/lib/types";
import {
  Button,
  Footer,
  Header,
  PostSummaryCard,
  Tag,
} from "../shared/components/atoms";
import {
  CalendarMonthIcon,
  ChevronRightIcon,
  ScheduleIcon,
} from "../shared/components/icons";
import Application from "../shared/components/layouts/Application";
import { viewEndAt, viewingTime } from "../shared/lib/studyGroupCardUtil";
import {
  SharedAnnouncements,
  SharedPost,
  SharedStudyGroup,
  UserSharedCurrentUser,
} from "../shared/lib/types";
import {
  BgCityDubble,
  BgCitySingle,
  BgCityTriple,
  HomeLeftDiagonalLine,
  HomeQAndA,
  HomeRightDiagonalLine,
  HomeStudyGroup,
  LogoSVG,
} from "../svg";
import TitleWithLinkButton from "./components/TitleWithLinkButton";
import Announcements from "./components/Announcements";

const RecommendContent = ({
  icon,
  title,
  subtitle,
  summary,
  features,
  linkLabel,
  buttonHref,
  ...props
}: {
  icon: ReactNode;
  title: string;
  subtitle: string;
  summary: ReactNode;
  features: ReactNode;
  linkLabel: string;
  buttonHref: string;
} & StackProps) => {
  return (
    <Grid
      background="white"
      px={{ base: 4, md: 6 }}
      pb={{ base: 7, md: 8 }}
      pt={{ base: 8, md: 8 }}
      gap={0}
      borderRadius={4}
      boxShadow="8px 8px 0px 0px rgba(26, 130, 123, 0.08)"
      border={{ md: "1px solid #D9EEE9" }}
      gridRow="span 6"
      gridTemplateRows="subgrid"
      justifyItems="center"
      textAlign="center"
      {...props}
    >
      <Box display="flex">{icon}</Box>
      <Heading as="span" color="primary" size="lg" mt={{ base: 4, md: 0 }}>
        {title}
      </Heading>
      <Box
        color="primary"
        fontWeight="bold"
        fontSize={{ base: "xs", md: "sm" }}
        mt={{ md: 1 }}
      >
        {subtitle}
      </Box>
      <Text
        align="center"
        mt="18px"
        fontSize={{ base: "sm", md: "md" }}
        wordBreak="keep-all"
        overflowWrap="anywhere"
      >
        {summary}
      </Text>
      <UnorderedList
        styleType="none"
        ml={0}
        fontSize="sm"
        mt={{ base: 3, md: 8 }}
      >
        {features}
      </UnorderedList>
      <Button
        w="full"
        mt={{ base: 5, md: 9 }}
        size="sm"
        as={Link}
        textDecoration="none"
        href={buttonHref}
      >
        {linkLabel}
      </Button>
    </Grid>
  );
};

const ResponsiveBgCityImage = () => {
  const responsiveBgCity = useBreakpointValue({
    base: BgCityDubble,
    "2xl": BgCityTriple,
  });

  return (
    <Image
      src={responsiveBgCity}
      h={{
        base: "calc(100vw / 5)",
        "2xl": "calc(100vw / 7.5)",
      }}
    />
  );
};

const NotUserContent = () => {
  return (
    <Box>
      <Container maxW="container.lg">
        <VStack gap={0}>
          <Box mr={6} px={14}>
            <Image
              src={LogoSVG}
              alt="Logo"
              mt="72px"
              minW={32}
              maxH="87px"
              h="calc(100vw/6.66)"
            />
          </Box>
          <Text align="center" mt={10} fontSize={{ base: "sm", md: "md" }}>
            A-Loopは、&#8203;建築士同士がつながるコミュニティサービスです。
            <br />
            設計業務のスキルアップを図るための&#8203;オンライン勉強会や建築士同士で興味関心のある
            <br />
            テーマについて質問や意見交換できる&#8203;オンラインコミュニティなどを提供します。
          </Text>
        </VStack>
      </Container>
      <Box mb="-9%" display={{ base: "block", md: "none" }}>
        <Image src={BgCitySingle} h="calc(100vw / 2.5)" />
      </Box>
      <Box backgroundColor={{ base: "#EDF5F3", md: "inherit" }} pb="27px">
        <Container
          maxW="container.lg"
          zIndex={1}
          position="relative"
          mb={{ base: 0, md: 56 }}
          pt={{ base: 12, md: 0 }}
          px={6}
        >
          <VStack gap={0}>
            <HStack gap={4} align="end" display={{ base: "flex", md: "none" }}>
              <Image src={HomeLeftDiagonalLine} w={6} />
              <Box textAlign="center" flex="1">
                <Box fontSize="sm" fontWeight="bold" color="primary">
                  はじめての方へ
                </Box>
                <Box fontSize="xl" fontWeight="bold" color="primary">
                  おすすめコンテンツ
                </Box>
              </Box>
              <Image src={HomeRightDiagonalLine} w={6} />
            </HStack>
            <Grid
              mt={{ base: 9, md: 12 }}
              gap={8}
              w="full"
              gridTemplateColumns="repeat(auto-fill, minmax(auto, 370px))"
              gridTemplateRows="repeat(6, auto)"
              justifyContent="center"
            >
              <RecommendContent
                icon={<Image src={HomeQAndA} h="114px" />}
                title="Q&A"
                subtitle="Q&A"
                summary={
                  <>
                    設計に関する疑問や関心のあることについて
                    <wbr />
                    質問やコメントができます。
                  </>
                }
                features={
                  <>
                    <ListItem>
                      ・建築士同士の情報交換で問題を効率的に解決
                    </ListItem>
                    <ListItem>
                      ・実名か匿名かを選択して質問・コメントが可能
                    </ListItem>
                  </>
                }
                linkLabel="Q&Aを見る"
                buttonHref={postsPath()}
              />
              <RecommendContent
                icon={<Image src={HomeStudyGroup} h="114px" p={4} />}
                title="勉強会"
                subtitle="STUDY"
                summary="講師の方をお招きした、&#8203;建築についての&#8203;様々な講義の動画が閲覧できます。"
                features={
                  <>
                    <ListItem>・建築士が主催する勉強会へ参加可能</ListItem>
                    <ListItem>・いつでも、どこでも動画視聴が可能</ListItem>
                  </>
                }
                linkLabel="動画を見に行く"
                buttonHref="#study-groups"
              />
            </Grid>
          </VStack>
        </Container>
      </Box>
      <Flex
        mt="-20%"
        mb={{ base: "-4.4%", "2xl": "-2.9%" }}
        display={{ base: "none", md: "flex" }}
      >
        <ResponsiveBgCityImage />
      </Flex>
    </Box>
  );
};

const HomeIndex = ({
  currentUser,
  flash,
  studyGroups,
  posts,
  announcements,
  announcementsCount,
}: {
  currentUser: UserSharedCurrentUser;
  flash: Flash;
  studyGroups: SharedStudyGroup[];
  posts: SharedPost[];
  announcements: SharedAnnouncements;
  announcementsCount: number;
}) => {
  useEffect(() => {
    if (location.hash !== "") {
      const tmp = location.hash;
      location.hash = "";
      location.hash = tmp;
    }
  }, []);

  useEffect(() => {
    if (location.search === "?error=1") {
      throw new Error();
    }
  }, []);

  return (
    <Application flash={flash} currentUser={currentUser}>
      <Header currentUser={currentUser} />
      <Box>
        {currentUser == null && <NotUserContent />}
        {currentUser != null && announcements.length !== 0 && (
          <Container maxW="container.lg" my={10}>
            <Announcements
              announcements={announcements}
              announcementsCount={announcementsCount}
            />
          </Container>
        )}
        <Box background="#EDF5F3" pb={{ base: 16, md: 32 }}>
          <Container maxW="container.lg" px={6}>
            <Box as="section" pt={{ base: "35px", md: "70px" }}>
              {currentUser == null && announcements.length !== 0 && (
                <Box mb={20}>
                  <Announcements
                    announcements={announcements}
                    announcementsCount={announcementsCount}
                  />
                </Box>
              )}
              <TitleWithLinkButton
                title="最新のQ&A"
                buttonLabel="Q&Aの一覧を見る"
                href={postsPath()}
              />
              {currentUser != null && (
                <Text
                  fontSize={{ base: "xs", md: "sm" }}
                  py={5}
                  px={{ base: 5, md: 6 }}
                  bgColor="#D9EEE9"
                  color="gray"
                  mt={4}
                >
                  設計に関する疑問や関心のあることについて質問やコメントができます。
                  <br />
                  ・建築士同士の情報交換で問題を効率的に解決
                  <br />
                  ・実名か匿名かを選択して質問・コメントが可能
                  <br />
                </Text>
              )}
              <Stack mt={{ base: 3.5, md: 5 }} gap={{ base: 4, md: 8 }}>
                <Stack gap={4}>
                  {posts.map((post) => (
                    <PostSummaryCard
                      key={post.code}
                      post={post}
                      currentUser={currentUser}
                    />
                  ))}
                </Stack>
                <Flex justify="center">
                  <Button
                    variant="outline"
                    as={Link}
                    textDecoration="none"
                    href={postsPath()}
                    size="sm"
                  >
                    <HStack gap={0.5}>
                      <Box as="span" pt={0.25}>
                        Q&Aの一覧を見る
                      </Box>
                      <ChevronRightIcon boxSize="18px" />
                    </HStack>
                  </Button>
                </Flex>
              </Stack>
            </Box>
            <Box as="section" id="study-groups" pt={{ base: 12, md: 32 }}>
              <TitleWithLinkButton
                title="最新の勉強会"
                buttonLabel="勉強会一覧を見る"
                href={studyGroupsPath()}
              />
              <Stack gap={4} mt={4}>
                {studyGroups.map((studyGroup) => (
                  <StudyGroupCard
                    studyGroup={studyGroup}
                    key={studyGroup.code}
                  />
                ))}
              </Stack>
              <Flex justify="center" mt={{ base: 4, md: 8 }}>
                <Button
                  variant="outline"
                  as={Link}
                  textDecoration="none"
                  href={studyGroupsPath()}
                  size="sm"
                >
                  <HStack gap={0.5}>
                    <Box as="span" pt={0.25}>
                      勉強会の一覧を見る
                    </Box>
                    <ChevronRightIcon boxSize="18px" />
                  </HStack>
                </Button>
              </Flex>
            </Box>
          </Container>
        </Box>
      </Box>
      <Footer />
    </Application>
  );
};

export const StudyGroupCard = ({
  studyGroup,
}: {
  studyGroup: SharedStudyGroup;
}) => {
  return (
    <Flex
      borderRadius={2}
      border="1px solid #E2E8F0"
      direction={{ base: "column", md: "row-reverse" }}
      as={Link}
      textDecoration="none"
      textColor="inherit"
      display="flex"
      href={studyGroupViewPath(studyGroup.code)}
    >
      <Box w={{ base: "auto", md: 96 }}>
        <Image
          src={studyGroup.thumbnail_url}
          w="full"
          aspectRatio={2 / 1}
          loading="lazy"
        />
      </Box>
      <Box flex={1} backgroundColor="white">
        <Flex direction={{ base: "column", md: "row" }}>
          <Flex
            textAlign="center"
            px={3}
            py={1.5}
            fontSize={{ base: "xs", sm: "sm" }}
            fontWeight="bold"
            color="white"
            backgroundColor="#009EC2"
            minW="120px"
            justify="center"
            align="center"
          >
            視聴可能
          </Flex>
          <Flex fontSize="xs" justify="space-between" py={3} px={4} flex={1}>
            <HStack gap={2}>
              <CalendarMonthIcon />
              <Box as="span" pt={0.5}>
                {viewEndAt(studyGroup)}
              </Box>
            </HStack>
            <HStack gap={2}>
              <ScheduleIcon />
              <Box as="span" pt={0.5}>
                {viewingTime(studyGroup)}
              </Box>
            </HStack>
          </Flex>
        </Flex>
        <Box as="hr" mx={{ base: 4, sm: 0 }} />
        <Box p={4}>
          <HStack wrap="wrap" gap={1}>
            {studyGroup.tags.map((tag) => (
              <Tag key={tag.id}>{tag.name}</Tag>
            ))}
          </HStack>
          <Text mt={2}>{studyGroup.title}</Text>
          <HStack mt={2} gap={1.5}>
            <Avatar
              boxSize={6}
              src={studyGroup.instructor_info.instructor_image_url}
              ignoreFallback
              loading="lazy"
            />
            <Box as="span" fontSize="sm">
              {studyGroup.instructor_info.name}
            </Box>
          </HStack>
        </Box>
      </Box>
    </Flex>
  );
};

export default HomeIndex;
