import { Link, LinkProps, forwardRef } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { isVisitableUserProfile } from "../../shared/lib/userProfileUtils";
import { userPath } from "../../../../routes";
import {
  UserSharedCurrentUser,
  HelperPostResourceAuthor,
  HelperRealNamePostResourceAuthor,
} from "../../shared/lib/types";

const UserProfileLink = forwardRef(
  (
    {
      author,
      currentUser,
      children,
      ...props
    }: {
      author: HelperPostResourceAuthor;
      currentUser: UserSharedCurrentUser;
      children: ReactNode;
    } & LinkProps,
    ref,
  ) => {
    return (
      <Link
        color="inherit"
        textDecoration="none"
        href={
          isVisitableUserProfile(author, currentUser)
            ? userPath((author as HelperRealNamePostResourceAuthor).code)
            : undefined
        }
        cursor={
          isVisitableUserProfile(author, currentUser)
            ? "pointer"
            : "default"
        }
        target="_blank"
        rel="noreferrer"
        ref={ref}
        {...props}
      >
        {children}
      </Link>
    );
  },
);

export default UserProfileLink;
