import {
  Box,
  Container,
  Flex,
  HStack,
  Heading,
  Hide,
  Image,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { ReactNode } from "react";
import { studyGroupViewPath } from "../../../routes";
import { Button } from "../shared/components/atoms";
import Background from "../shared/components/atoms/Background";
import CustomLinkLinkify from "../shared/components/atoms/CustomLinkLinkify";
import Header from "../shared/components/atoms/Header";
import InfoMessage from "../shared/components/atoms/InfoMessage";
import Tag from "../shared/components/atoms/Tag";
import CalendarMonthIcon from "../shared/components/icons/CalendarMonth";
import PlayCircleIcon from "../shared/components/icons/PlayCircleIcon";
import ScheduleIcon from "../shared/components/icons/ScheduleIcon";
import Application from "../shared/components/layouts/Application";
import Footer from "../shared/components/atoms/Footer";
import { secondsToHoursMinutes } from "../shared/lib/dateUtils";
import { StudyGroup, UserSharedCurrentUser } from "../shared/lib/types";
import { Flash } from "../../shared/lib/types";
import StudyGroupCaution from "../shared/components/atoms/StudyGroupCaution";
import RegistrationRequiredAction from "../shared/components/atoms/RegistrationRequiredAction";

const Section = ({
  sectionTitle,
  children,
}: {
  sectionTitle: string;
  children: ReactNode;
}) => {
  return (
    <Box>
      <Heading
        size={{ base: "sm", md: "md" }}
        pb={2}
        mb={{ base: 4, lg: 6 }}
        borderBottomColor="#E6E6E6"
        borderBottomWidth="1px"
      >
        {sectionTitle}
      </Heading>
      {children}
    </Box>
  );
};

const WatchButton = ({
  study_group_code,
  onClick,
  currentUser,
}: {
  study_group_code: string;
  onClick: React.MouseEventHandler;
  currentUser: UserSharedCurrentUser;
}) => {
  return (
    <Button
      as="a"
      mt={6}
      width="100%"
      size={{ base: "sm", lg: "lg" }}
      href={
        currentUser?.is_all_public_feature_accessible
          ? studyGroupViewPath(study_group_code)
          : undefined
      }
      onClick={onClick}
    >
      <PlayCircleIcon boxSize={{ base: 5, md: 9 }} mr={2} />
      <Box as="span" pt={1}>
        動画を視聴する
      </Box>
    </Button>
  );
};

const StudyGroupsShow = ({
  studyGroup,
  currentUser,
  flash,
}: {
  studyGroup: StudyGroup;
  currentUser: UserSharedCurrentUser;
  flash: Flash;
}) => {
  return (
    <Application flash={flash} currentUser={currentUser}>
      <Header currentUser={currentUser} />
      <Background color="#EDF5F3">
        <Container maxW="container.lg">
          {!studyGroup.is_published && (
            <InfoMessage mt={4}>
              非公開の勉強会です。公式アカウントのみが閲覧することができます。
            </InfoMessage>
          )}
          {!studyGroup.is_started && (
            <InfoMessage mt={4}>
              公開期間開始前の勉強会です。公式アカウントのみが閲覧することができます。
            </InfoMessage>
          )}
        </Container>
        <Container mt={8} maxW="container.lg">
          <Image
            src={studyGroup.thumbnail_url}
            w="100%"
            borderRadius="4px"
            aspectRatio={2 / 1}
          />
        </Container>
        <Container mt={4} maxW="container.lg">
          {studyGroup.is_ended && (
            <Flex
              bgColor="#969696"
              py={3}
              px={5}
              borderTopRadius="2px"
              justify="center"
            >
              <Text
                fontSize={{ base: "md", md: "3xl" }}
                fontWeight="bold"
                color="#fff"
                textAlign="center"
              >
                公開期間が終了したため、
                <Hide above="md">
                  <br />
                </Hide>
                視聴できません
              </Text>
            </Flex>
          )}
          <Box
            bgColor="#fff"
            px={{ base: 4, md: 32 }}
            py={{ base: 5, md: 14 }}
            borderRadius="2px"
          >
            <Container maxW="container.md" p={0}>
              <Heading as="h1" size={{ base: "md", md: "lg", lg: "xl" }}>
                {studyGroup.title}
              </Heading>
              <HStack mt={2} gap={0.5}>
                <CalendarMonthIcon />
                <Text fontSize={{ base: "sm", md: "md" }} pt={0.5}>
                  {studyGroup.ended_at && studyGroup.is_ended
                    ? `${dayjs(studyGroup.ended_at).format(
                        "L(dd) HH:mm",
                      )}公開終了`
                    : studyGroup.ended_at
                      ? `${dayjs(studyGroup.ended_at).format(
                          "L(dd) HH:mm",
                        )}まで視聴可能`
                      : "視聴期限無し"}
                </Text>
              </HStack>

              {!studyGroup.is_ended ? (
                <RegistrationRequiredAction
                  currentUser={currentUser}
                  render={(props) => (
                    <WatchButton
                      study_group_code={studyGroup.code}
                      currentUser={currentUser}
                      {...props}
                    />
                  )}
                />
              ) : (
                <Button
                  mt={6}
                  width="100%"
                  size={{ base: "md", lg: "lg" }}
                  isDisabled
                >
                  <Text
                    pt={1}
                    fontSize={{ base: "md", md: "2xl" }}
                    textAlign="center"
                    lineHeight="1.5"
                  >
                    公開期間が終了したため、
                    <Hide above="md">
                      <br />
                    </Hide>
                    視聴できません
                  </Text>
                </Button>
              )}
              <HStack mt={2} gap={0.5} justify="center">
                <ScheduleIcon />
                <Text fontSize={{ base: "sm", lg: "md" }}>
                  {studyGroup.viewing_time
                    ? `${secondsToHoursMinutes(studyGroup.viewing_time)}`
                    : "計算中..."}
                </Text>
              </HStack>
              <Stack gap={{ base: 7, md: 10 }} mt={6}>
                <Section sectionTitle="概要">
                  <Flex gap={1} wrap="wrap">
                    {studyGroup.tags.map(({ id, name }) => (
                      <Tag key={id}>{name}</Tag>
                    ))}
                  </Flex>
                  <Box
                    fontSize={{ base: "sm", md: "md" }}
                    mt={3}
                    whiteSpace="pre-wrap"
                  >
                    <CustomLinkLinkify>
                      {studyGroup.description}
                    </CustomLinkLinkify>
                  </Box>
                </Section>
                <Section sectionTitle="講師">
                  <Flex
                    align="center"
                    gap={{ base: 4, lg: 10 }}
                    direction={{ base: "column", lg: "row" }}
                  >
                    <Box flexShrink={0}>
                      {studyGroup.instructor_info.user_url === "" ||
                      studyGroup.instructor_info.is_canceled ? (
                        <Image
                          w={32}
                          borderRadius="50%"
                          aspectRatio={1 / 1}
                          src={studyGroup.instructor_info.instructor_image_url}
                        />
                      ) : (
                        <RegistrationRequiredAction
                          currentUser={currentUser}
                          href={studyGroup.instructor_info.user_url}
                          render={(props) => (
                            <Link {...props} target="_blank" rel="noreferrer">
                              <Image
                                w={32}
                                borderRadius="50%"
                                aspectRatio={1 / 1}
                                src={
                                  studyGroup.instructor_info
                                    .instructor_image_url
                                }
                              />
                            </Link>
                          )}
                        />
                      )}
                    </Box>
                    <Box>
                      {studyGroup.instructor_info.user_url === "" ||
                      studyGroup.instructor_info.is_canceled ? (
                        <Text>{studyGroup.instructor_info.name}</Text>
                      ) : (
                        <RegistrationRequiredAction
                          currentUser={currentUser}
                          href={studyGroup.instructor_info.user_url}
                          render={(props) => (
                            <Link {...props} target="_blank" rel="noreferrer">
                              {studyGroup.instructor_info.name}
                            </Link>
                          )}
                        />
                      )}
                      <Box
                        mt={{ base: 2, lg: 4 }}
                        fontSize="sm"
                        whiteSpace="pre-wrap"
                        wordBreak="break-word"
                      >
                        <CustomLinkLinkify>
                          {studyGroup.instructor_info.introduction}
                        </CustomLinkLinkify>
                      </Box>
                    </Box>
                  </Flex>
                </Section>
                <Section sectionTitle="主催者">
                  <HStack>
                    <Image
                      w={8}
                      borderRadius="50%"
                      aspectRatio={1 / 1}
                      src={studyGroup.organizer.profile_image_url}
                    />
                    <Text pt={0.5}>{studyGroup.organizer.full_name}</Text>
                  </HStack>
                </Section>
                {!studyGroup.is_ended && (
                  <RegistrationRequiredAction
                    currentUser={currentUser}
                    render={(props) => (
                      <WatchButton
                        study_group_code={studyGroup.code}
                        currentUser={currentUser}
                        {...props}
                      />
                    )}
                  />
                )}
              </Stack>
            </Container>
          </Box>
          <Box mt={{ base: 8, md: 14 }}>
            <StudyGroupCaution />
          </Box>
        </Container>
      </Background>
      <Footer />
    </Application>
  );
};

export default StudyGroupsShow;
