import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const PlayCircleIcon = (props: IconProps) => (
  <Icon viewBox="0 0 20 20" boxSize={5} {...props}>
    <path
      d="M7.91675 13.7498L13.7501 9.99984L7.91675 6.24984V13.7498ZM10.0001 18.3332C8.8473 18.3332 7.76397 18.1144 6.75008 17.6769C5.73619 17.2394 4.85425 16.6457 4.10425 15.8957C3.35425 15.1457 2.7605 14.2637 2.323 13.2498C1.8855 12.2359 1.66675 11.1526 1.66675 9.99984C1.66675 8.84706 1.8855 7.76373 2.323 6.74984C2.7605 5.73595 3.35425 4.854 4.10425 4.104C4.85425 3.354 5.73619 2.76025 6.75008 2.32275C7.76397 1.88525 8.8473 1.6665 10.0001 1.6665C11.1529 1.6665 12.2362 1.88525 13.2501 2.32275C14.264 2.76025 15.1459 3.354 15.8959 4.104C16.6459 4.854 17.2397 5.73595 17.6772 6.74984C18.1147 7.76373 18.3334 8.84706 18.3334 9.99984C18.3334 11.1526 18.1147 12.2359 17.6772 13.2498C17.2397 14.2637 16.6459 15.1457 15.8959 15.8957C15.1459 16.6457 14.264 17.2394 13.2501 17.6769C12.2362 18.1144 11.1529 18.3332 10.0001 18.3332Z"
      fill="currentColor"
    />
  </Icon>
);

export default PlayCircleIcon;
