import React from "react";
import {
  Container,
  Flex,
  Image,
  Text,
  Stack,
  Link,
  VStack,
  HStack,
} from "@chakra-ui/react";
import ShadowCard from "../shared/components/atoms/ShadowCard";
import Application from "../shared/components/layouts/Application";
import { newUserPersonalInfoApplicationPath, rootPath } from "../../../routes";
import FixedBackgroundBuildingImage from "../shared/components/atoms/FixedBackgroundBuildingImage";
import Background from "../shared/components/atoms/Background";
import iDCardImage from "./svg/img-idCard.svg";
import ArrowIcon from "./svg/img-arrow.svg";
import SeeAllMessageImage from "./svg/img-seeAllMessage.svg";
import talkImage from "./svg/img-talk.svg";
import watchVideoImage from "./svg/img-watchVideo.svg";
import { Flash } from "../../shared/lib/types";
import { LogoSVG } from "../svg";

const ConfirmationDonesShow = ({ flash }: { flash: Flash }) => {
  return (
    <Application flash={flash}>
      <FixedBackgroundBuildingImage />
      <Background>
        <Container maxW="4xl" mt={{ base: 6, md: 20 }} zIndex={1}>
          <ShadowCard>
            <Flex direction="column" align="center">
              <Image src={LogoSVG} width={{ base: 32, lg: 48 }} />
              <Text
                fontSize={{ base: "lg", md: "2rem" }}
                fontWeight="600"
                mt={{ base: 6, md: 10 }}
              >
                仮登録が完了しました
              </Text>
              <Text
                mt={{ base: 7, lg: 10 }}
                lineHeight="170%"
                fontSize={{ base: "sm", md: "md" }}
                textAlign="center"
                as="span"
              >
                <Text display="inline-block">
                  A-Loopでは、建築士の皆さまが安心して
                </Text>
                <Text display="inline-block">
                  交流できる場をご提供するため、
                </Text>
                <br />
                <Text display="inline-block" fontWeight="600" color="primary">
                  本人確認が完了したユーザーのみ
                </Text>
                <Text display="inline-block">
                  すべての機能を使用することができます。{" "}
                </Text>
              </Text>
              <HStack gap={2}>
                <Text fontWeight="bold" mt={10}>
                  ＼
                </Text>
                <Text fontWeight="bold" mt={10}>
                  続けて入力
                </Text>
                <Text fontWeight="bold" mt={10}>
                  ／
                </Text>
              </HStack>
              <Flex
                as="a"
                mt={2}
                borderRadius="8px"
                gap={6}
                pl={6}
                pr={5}
                pt={6}
                pb={5}
                borderColor="primary"
                borderWidth="2px"
                cursor="pointer"
                transition="0.3s"
                _hover={{ bgColor: "brand.50" }}
                href={newUserPersonalInfoApplicationPath()}
                alignItems="center"
              >
                <Stack
                  gap={{ base: 2, sm: 4 }}
                  direction={{ base: "column", sm: "row" }}
                  align="center"
                >
                  <Image mb={0.5} src={iDCardImage} width="3rem" />
                  <Stack
                    pt={0.5}
                    gap={{ base: 0, sm: 2 }}
                    direction={{ base: "column", sm: "row" }}
                    align={{ base: "flex-start", sm: "center" }}
                  >
                    <Text
                      fontSize={{ base: "md", md: "xl" }}
                      color="primary"
                      fontWeight="600"
                    >
                      本人確認の登録
                    </Text>
                    <Text fontWeight="bold">に進む</Text>
                  </Stack>
                </Stack>
                <Image src={ArrowIcon} width="0.75rem" />
              </Flex>
              <VStack
                mt={12}
                bgColor="brand.50"
                width={{ base: "100%", md: "95%" }}
                px={{ base: 6, md: 10 }}
                py={{ base: 6, md: 8 }}
                gap={{ base: 4, md: 5 }}
                textAlign="center"
              >
                <Text fontSize="lg" fontWeight="bold">
                  本人確認が完了するとできること
                </Text>
                <Flex
                  direction={{ base: "column", sm: "row" }}
                  gap={{ base: 4, sm: 0 }}
                >
                  <Stack flex={1} align="center" gap={0}>
                    <Image boxSize="8rem" src={watchVideoImage} />
                    <Text fontWeight="bold" fontSize="sm" textAlign="left">
                      勉強会の動画視聴が可能
                    </Text>
                  </Stack>
                  <Stack flex={1} align="center" gap={0}>
                    <Image boxSize="8rem" src={talkImage} />
                    <Text fontWeight="bold" fontSize="sm" textAlign="left">
                      勉強会で質問や投稿が可能
                    </Text>
                  </Stack>
                  <Stack flex={1} align="center" gap={0}>
                    <Image boxSize="8rem" src={SeeAllMessageImage} />
                    <Text fontWeight="bold" fontSize="sm" textAlign="left">
                      Q&Aに投稿されたすべての質問、コメントが閲覧可能
                    </Text>
                  </Stack>
                </Flex>
              </VStack>
              <Link
                mt={10}
                color="textLink"
                textDecoration="underline"
                _hover={{ textDecoration: "none" }}
                fontSize={{ base: "sm", md: "1.25rem" }}
                href={rootPath()}
              >
                本人確認の登録をせずA-Loopを利用する
              </Link>
            </Flex>
          </ShadowCard>
        </Container>
      </Background>
    </Application>
  );
};

export default ConfirmationDonesShow;
