import { Box, Container, Flex, Image, Link, Text } from "@chakra-ui/react";
import React from "react";
import { newUserDatabaseAuthenticationRegistrationPath } from "../../../routes";
import Background from "../shared/components/atoms/Background";
import FixedBackgroundBuildingImage from "../shared/components/atoms/FixedBackgroundBuildingImage";
import ShadowCard from "../shared/components/atoms/ShadowCard";
import Application from "../shared/components/layouts/Application";
import { Flash } from "../../shared/lib/types";
import { LogoSVG } from "../svg";
import EmailNotReceivedMessage from "../../shared/components/EmailNotReceivedMessage";

const ConfirmationSentsShow = ({ flash }: { flash: Flash }) => {
  return (
    <Application flash={flash}>
      <FixedBackgroundBuildingImage />
      <Background>
        <Container maxW="4xl" mt={{ base: 6, lg: 20 }} zIndex={1}>
          <ShadowCard>
            <Flex direction="column" align="center">
              <Image src={LogoSVG} width={{ base: 32, lg: 48 }} />
              <Text
                fontSize={{ base: "md", md: "2rem" }}
                fontWeight="600"
                mt={{ base: 6, md: 10 }}
              >
                確認メールを送信しました
              </Text>
              <Box
                mt={{ base: 7, lg: 10 }}
                lineHeight="170%"
                fontSize={{ base: "sm", lg: "md" }}
                textAlign="center"
                display={{ base: "block", md: "none" }}
              >
                <Text>
                  <Text as="span" display="inline-block">
                    ご登録いただいたメールアドレスに
                  </Text>
                  <Text as="span" display="inline-block">
                    確認メールを送信しました。
                  </Text>
                </Text>
                <Text mt={4}>
                  <Text as="span" display="inline-block">
                    メール内のURLを<b>60分以内</b>に
                  </Text>
                  <Text as="span" display="inline-block">
                    クリックして仮登録を行ってください。
                  </Text>
                </Text>
              </Box>
              <Text
                mt={{ base: 7, lg: 10 }}
                lineHeight="170%"
                fontSize={{ base: "sm", lg: "md" }}
                textAlign={{ base: "left", lg: "center" }}
                display={{ base: "none", md: "block" }}
              >
                ご登録いただいたメールアドレスに確認メールを送信しました。{" "}
                <br />
                メール内のURLを<b>60分以内</b>
                にクリックして仮登録を行ってください。
              </Text>
              <EmailNotReceivedMessage mt={10} />
              <Link
                href={newUserDatabaseAuthenticationRegistrationPath()}
                mt={6}
                fontSize="sm"
                color="textLink"
                textDecoration="underline"
                _hover={{ textDecoration: "none" }}
              >
                会員登録画面に戻る
              </Link>
            </Flex>
          </ShadowCard>
        </Container>
      </Background>
    </Application>
  );
};

export default ConfirmationSentsShow;
