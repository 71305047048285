import { Box, Container, Grid, Heading, Image, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { Flash, SharedTag } from "../../shared/lib/types";
import Background from "../shared/components/atoms/Background";
import Footer from "../shared/components/atoms/Footer";
import Select from "../shared/components/atoms/form/Select";
import Header from "../shared/components/atoms/Header";
import Application from "../shared/components/layouts/Application";
import { StudyGroups, UserSharedCurrentUser } from "../shared/lib/types";
import StudyGroupCard from "./components/StudyGroupCard";
import { studyGroupsPath } from "../../../routes";
import { StudyGroupSVG } from "./svg";

const StudyGroupsIndex = ({
  studyGroups,
  tags,
  currentUser,
  flash,
}: {
  studyGroups: StudyGroups;
  tags: SharedTag[];
  currentUser: UserSharedCurrentUser;
  flash: Flash;
}) => {
  const ref = useRef<HTMLSelectElement>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.value =
        new URLSearchParams(location.search).get("q[tags_id_eq]") ?? "";
    }
  }, []);
  return (
    <Application flash={flash} currentUser={currentUser}>
      <Header currentUser={currentUser} />
      <Background color="#EDF5F3">
        <Container maxW="container.xl" mt={{ base: 8, sm: 14 }}>
          <Heading as="h1" size={{ base: "md", sm: "lg" }}>
            勉強会一覧
          </Heading>

          <Box maxW={{ base: undefined, sm: 80 }} mt={{ base: 8, sm: 12 }}>
            <Select
              label="表示中タグ"
              ref={ref}
              onChange={(e) => {
                location.href = studyGroupsPath({
                  q: { tags_id_eq: e.target.value },
                });
              }}
            >
              {[{ id: "", name: "すべて" }, ...tags].map((tag) => (
                <option key={tag.id} value={tag.id}>
                  {tag.name}
                </option>
              ))}
            </Select>
          </Box>

          {studyGroups.length === 0 ? (
            <VStack mt={20}>
              <Image src={StudyGroupSVG} />
              <Text textAlign="center" color="gray" fontSize={{base: "sm", sm: "md"}}>
                現在公開中の勉強会はありません <br />
                新しい勉強会が公開されるのをお待ちください
              </Text>
            </VStack>
          ) : (
            <Grid
              templateColumns="repeat(auto-fill, minmax(288px, 1fr))"
              gap={4}
              mt={8}
              justifyItems="center"
            >
              {studyGroups.map((studyGroup) => (
                <StudyGroupCard
                  key={studyGroup.code}
                  studyGroup={studyGroup}
                />
              ))}
            </Grid>
          )}
        </Container>
      </Background>
      <Footer />
    </Application>
  );
};

export default StudyGroupsIndex;
